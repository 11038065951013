<template>
  <div class="col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 col-xxl-5">
    <div class="row justify-content-center">
      <div class="col-12 mb-3 text-center font19 medium" >
        Pay Online Via Card
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 text-center" >
        Please enter the card details you<br/>would like to use for this donation:
      </div>
      <div class="col-12 mt-4 pt-2">
        <StripeSection @stripeContinue="continueSetup" @sadaqahComplete="sadaqahComplete" :ukDailySadaqahSubscription="ukDailySadaqahSubscription" :turnstileReturn="turnstileReturn" v-model:isLoading="isLoadingValue" />
      </div>
    </div>
    <transition name="fade">
      <TurnstilePopup v-if="isTurnstile" @close="closeTurnstile" @verify="verifyTurnstile"/>
    </transition>
  </div>
</template>
<script>

import { defineAsyncComponent } from 'vue'
import { mapActions } from 'vuex'

export default {
  components: {
    StripeSection: defineAsyncComponent(() => import('@/views/stripe/StripeSection.vue')),
    TurnstilePopup: defineAsyncComponent(() => import('@/views/TurnstilePopup.vue'))
  },
  props: ['sadaqahSavedReturn', 'isLoading'],
  emits: ['update:isLoading', 'saveSadaqah', 'sadaqahComplete'],
  data () {
    return {
      isTurnstile: false,
      turnstileReturn: null,
      ukDailySadaqahSubscription: null
    }
  },
  watch: {
    async sadaqahSavedReturn () {
      if (this.sadaqahSavedReturn) {
        await this.checkTurnstile()
      }
    }
  },
  computed: {
    isLoadingValue: {
      get () {
        return this.isLoading
      },
      set (value) {
        this.$emit('update:isLoading', value)
      }
    }
  },
  methods: {
    ...mapActions(['']),
    async continueSetup () {
      this.isLoadingValue = true
      await this.$emit('saveSadaqah')
    },
    async startPaymentProcess () {
      this.isLoadingValue = true
      if (this.sadaqahSavedReturn.data) {
        this.ukDailySadaqahSubscription = this.sadaqahSavedReturn.data
      }
    },
    closeTurnstile () {
      this.isLoadingValue = false
      this.turnstileReturn = null
      this.isTurnstile = false
    },
    async verifyTurnstile (val) {
      this.turnstileReturn = val
      this.isTurnstile = false
      if (this.turnstileReturn) {
        await this.startPaymentProcess()
      }
    },
    checkTurnstile () {
      this.isTurnstile = true
    },
    sadaqahComplete (val) {
      this.$emit('sadaqahComplete', val)
    }
  }
}
</script>
